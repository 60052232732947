<template>
  <admin-container>
    <div class="text-red-500 uppercase">Testing tv archive</div>

    <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mb-4">
      <div class="col-span-1 md:col-span-2">
        <label
          for="channelName"
          class="block mb-2 text-sm font-medium text-gray-900"
          >Сувгийн нэр
        </label>
        <select
          id="channelName"
          name="channelName"
          v-model="filterValues.channelName"
          :value="filterValues.channelName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option selected disabled>Төрөл сонгох</option>
          <option value="1">MNB Үндэсний телевиз</option>
          <option value="2">MNB Монголын мэдээ</option>
          <option value="3">MNB Спорт</option>
          <option value="4">MNB World</option>
          <option value="5">MNB Гэр бүл</option>
        </select>
      </div>

      <div class="col-span-1 md:col-span-2">
        <label
          for="archiveStatus"
          class="block mb-2 text-sm font-medium text-gray-900"
          >Архивлагдсан эсэх
        </label>
        <select
          id="archiveStatus"
          name="archiveStatus"
          v-model="filterValues.archiveStatus"
          :value="filterValues.archiveStatus"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option selected disabled>Төрөл сонгох</option>
          <option value="true">Тийм</option>
          <option value="false">Үгүй</option>
        </select>
      </div>

      <div class="col-span-1 md:col-span-2">
        <label for="date" class="block mb-2 text-sm font-medium text-gray-900"
          >Огноо</label
        >
        <input
          type="date"
          id="date"
          name="date"
          v-model="filterValues.date"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
      </div>

      <div class="col-span-1 md:col-span-2">
        <label for="search" class="block mb-2 text-sm font-medium text-gray-900"
          >Хайх</label
        >
        <div class="relative">
          <input
            type="text"
            id="search"
            name="search"
            placeholder="Хөтөлбөрийн нэр"
            v-model="filterValues.search"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
          <button
            type="button"
            class="absolute right-2 top-1/2 -translate-y-1/2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            @click="fetchTvArchiveList"
          >
            <svg
              class="w-5 h-5 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="10" cy="10" r="7"></circle>
              <line x1="21" y1="21" x2="15" y2="15"></line>
            </svg>
          </button>
        </div>
      </div>

      <div class="col-span-1 md:col-span-2">
        <label class="block mb-2 text-sm font-medium text-gray-900 invisible"
          >Hidden</label
        >

        <button
          class="bg-green-500 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          @click="isAddModalOpen = true"
        >
          Хөтөлбөр нэмэх
        </button>
      </div>

      <div class="col-span-1 md:col-span-1">
        <label class="block mb-2 text-sm font-medium text-gray-900 invisible"
          >Hidden</label
        >
        <button
          type="button"
          @click="clearFilters"
          class="w-10 h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
        >
          <font-awesome-icon :icon="['fas', 'times']" />
        </button>
      </div>
    </div>

    <div
      class="overflow-x-hidden"
      v-if="!isLoading && tvArchiveList.length > 0"
    >
      <hot-table :settings="hotSettings" :data="tvArchiveList" ref="hotRef" />
    </div>
    <div class="mt-4" v-if="!isLoading && tvArchiveList.length === 0">
      <p class="text-center text-gray-500">Хөтөлбөр олдсонгүй</p>
    </div>

    <div
      class="fixed inset-0 bg-black bg-opacity-50 z-[10000]"
      v-if="isVideoModalOpen || isDeleteModalOpen"
      @click="
        isVideoModalOpen = false;
        isDeleteModalOpen = false;
      "
    ></div>

    <div
      class="absolute top-[30%] l-4 r-4 md:fixed md:top-1/2 md:left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 overflow-hidden transition-all duration-300 ease-in-out flex flex-col items-center justify-center z-[10001]"
      v-if="isVideoModalOpen"
    >
      <video-player :filename="videoName" />
    </div>

    <delete-modal
      v-if="isDeleteModalOpen"
      @close="isDeleteModalOpen = false"
      @delete="deleteTvArchive"
      :title="seletectedRowData[2]"
      message="Та устгахдаа итгэлтэй байна уу?"
    />

    <div
      v-if="isAddModalOpen"
      class="fixed inset-0 z-[9999] flex items-center justify-center"
    >
      <div
        class="absolute inset-0 bg-black bg-opacity-50"
        @click="isAddModalOpen = false"
      ></div>

      <div
        class="relative bg-white p-6 rounded shadow-lg max-w-lg mx-auto w-11/12 md:w-auto"
      >
        <button
          @click="isAddModalOpen = false"
          class="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-xl"
        >
          &times;
        </button>

        <h2 class="text-lg font-bold mb-4">Хөтөлбөр нэмэх</h2>

        <div class="mb-4">
          <label
            for="channelSelection"
            class="block mb-2 text-sm font-medium text-gray-900"
            >Сувгийн нэр</label
          >
          <select
            id="channelSelection"
            v-model="addModalValues.channelId"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 w-full"
          >
            <option selected disabled>Төрөл сонгох</option>
            <option value="1">MNB Үндэсний телевиз</option>
            <option value="2">MNB Монголын мэдээ</option>
            <option value="3">MNB Спорт</option>
            <option value="4">MNB World</option>
            <option value="5">MNB Гэр бүл</option>
          </select>
        </div>

        <div class="mb-4">
          <label
            for="excelFile"
            class="block mb-2 text-sm font-medium text-gray-900"
            >Файл сонгох (Excel)</label
          >
          <input
            type="file"
            id="excelFile"
            accept=".xls,.xlsx"
            class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
            @change="handleFileInput"
          />
        </div>

        <div class="flex justify-end">
          <button
            @click="submitExcelFile"
            class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
          >
            Нэмэх
          </button>
        </div>
      </div>
    </div>
  </admin-container>
</template>

<script>
import { ref, onMounted, watch, reactive, computed } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";

import AdminContainer from "../admin/AdminContainer.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import DeleteModal from "@/components/ui/DeleteModal.vue";

registerAllModules();

export default {
  name: "VideoPage",
  components: {
    VideoPlayer,
    AdminContainer,
    DeleteModal,
    HotTable,
  },
  setup() {
    const store = useStore();
    const hotRef = ref(null);
    const isLoading = computed(() => store.state.admin.isLoading);
    const tvArchiveList = computed(() => store.state.admin.tvArchiveList);

    const videoName = ref("");
    const seletectedRowData = ref(null);

    const isAddModalOpen = ref(false);

    const addModalValues = reactive({
      channelId: "",
      excelFile: null,
    });

    const handleFileInput = (e) => {
      addModalValues.excelFile = e.target.files[0] || null;
    };

    const submitExcelFile = async () => {
      if (!addModalValues.channelId) {
        alert("Сувгийн нэрээ сонгоно уу.");
        return;
      }
      if (!addModalValues.excelFile) {
        alert("Excel файл сонгоно уу.");
        return;
      }

      isAddModalOpen.value = false;

      const formData = new FormData();
      formData.append("channelId", addModalValues.channelId);
      formData.append("excelFile", addModalValues.excelFile);

      await store.dispatch("admin/uploadTvSchedule", formData);
    };

    const isDeleteModalOpen = ref(false);
    const isVideoModalOpen = ref(false);

    const filterValues = reactive({
      channelName: "",
      archiveStatus: "",
      date: "",
      search: "",
    });

    onMounted(() => {
      fetchTvArchiveList();
    });

    watch(
      () => [
        filterValues.channelName,
        filterValues.archiveStatus,
        filterValues.date,
      ],
      () => {
        fetchTvArchiveList();
      },
      { deep: true }
    );

    const fetchTvArchiveList = async () => {
      store.dispatch("admin/fetchTvArchiveList", filterValues);
    };

    /*
      action renderer for handsontable
    */
    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");
      const watchButtonId = `edit-button-${row}`;
      const deleteButtonId = `confirm-button-${row}`;

      td.innerHTML = `<div class="flex justify-center items-center gap-2 py-1">
        <button id="${watchButtonId}" class="w-28 bg-blue-500 text-white rounded-md text-center p-1 hover:bg-blue-600 transition ease-in-out duration-150">Үзэх</button>
        <button id="${deleteButtonId}" class="w-28 bg-red-500 text-white rounded-md text-center p-1 hover:bg-red-600 transition ease-in-out duration-150">Устгах</button>
      </div>`;

      const editButton = document.getElementById(watchButtonId);
      const deleteButtonElement = document.getElementById(deleteButtonId);
      const fileName = instance.getDataAtRowProp(row, "file_name");

      if (editButton) {
        editButton.removeEventListener(
          "click",
          watchClickHandler.bind(null, fileName)
        );
        editButton.addEventListener(
          "click",
          watchClickHandler.bind(null, fileName)
        );
      }
      if (deleteButtonElement) {
        deleteButtonElement.removeEventListener("click", deleteClickHandler);
        deleteButtonElement.addEventListener("click", deleteClickHandler);
      }
    };

    function watchClickHandler(fileName) {
      isVideoModalOpen.value = true;
      videoName.value = fileName;
    }

    function deleteClickHandler() {
      isDeleteModalOpen.value = true;
      const hotInstance = hotRef.value.hotInstance;
      const selectedRow = hotInstance.getSelectedLast()[0];
      seletectedRowData.value = hotInstance.getDataAtRow(selectedRow);
    }

    async function deleteTvArchive() {
      try {
        await store.dispatch(
          "admin/deleteTvArchive",
          seletectedRowData.value[0]
        );
        await fetchTvArchiveList();
      } catch (err) {
        console.error(err);
      } finally {
        isDeleteModalOpen.value = false;
      }
    }

    const hotSettings = {
      data: tvArchiveList.value,
      colHeaders: [
        "",
        "Сувгийн нэр",
        "Хөтөлбөрийн нэр",
        "Огноо",
        "Эхлсэн цаг",
        "Дууссан цаг",
        "Файлын нэр",
        "Архивлагдсан эсэх",
        "Өөрчлөлт орсон огноо",
        "Үйлдэл",
      ],
      colWidths: [30, 200, 250, 150, 150, 150, 250, 150, 200, 150],
      columns: [
        // make id hidden
        { data: "id" },
        { data: "channel_name" },
        { data: "program_name" },
        { data: "date" },
        { data: "start_time" },
        { data: "end_time" },
        { data: "file_name" },
        {
          data: "is_archived",
          renderer: function (instance, td, row, col, prop, value) {
            if (value === true) {
              td.style.color = "green";
              td.style.fontWeight = "bold";
              td.style.textAlign = "center";
              td.innerHTML = "Тийм";
            } else {
              td.style.color = "red";
              td.style.fontWeight = "bold";
              td.style.textAlign = "center";
              td.innerHTML = "Үгүй";
            }
          },
        },
        { data: "updated_at" },
        {
          data: "action",
          readOnly: true,
          renderer: actionRenderer,
        },
      ],
      hiddenColumns: {
        columns: [0],
        indicators: false,
      },
      fixedColumnsLeft: window.innerWidth < 768 ? 0 : 2,
      width: "100%",
      height: "85vh",
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    /*
      Clear filters
    */
    const clearFilters = () => {
      filterValues.channelName = "";
      filterValues.archiveStatus = "";
      filterValues.date = "";
      filterValues.search = "";
      fetchTvArchiveList();
    };

    return {
      isDeleteModalOpen,
      isVideoModalOpen,
      isLoading,
      filterValues,
      videoName,
      tvArchiveList,
      hotRef,
      hotSettings,
      seletectedRowData,
      clearFilters,
      fetchTvArchiveList,
      deleteTvArchive,

      // For "Add" modal
      isAddModalOpen,
      addModalValues,
      handleFileInput,
      submitExcelFile,
    };
  },
};
</script>

<style></style>

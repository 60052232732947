import { adminInstance } from "@/lib/adminInstance";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    success: null,

    tvArchiveList: [],
    categories: [],
    vodList: [],
    episodeList: [],

    videoData: {
      video: null,
      videoName: "",
      videoCategory: "",
      videoThumbnail: null,
      videoPrice: "",
      videoDescription: "",
      isSeries: false,
    },

    seriesData: {
      videoFile: null,
      seriesTitle: "",
      seriesCategory: "",
      seriesThumbnail: null,
      seriesDescription: "",
      seriesPrice: "",
      episodeTitle: "",
      episodeNumber: "",
      episodeDescription: "",
      isSeries: true,
    },
  },

  getters: {
    getIsLoading: (state) => state.isLoading,
    getError: (state) => state.error,
    getSuccess: (state) => state.success,
    getTvArchiveList: (state) => state.tvArchiveList,
    getCategories: (state) => state.categories,
    getVodList: (state) => state.vodList,
    getVideoData: (state) => state.videoData,
    getSeriesData: (state) => state.seriesData,
  },
  mutations: {
    setIsLoading: (state, payload) => (state.isLoading = payload),
    setError: (state, payload) => (state.error = payload),
    setSuccess: (state, payload) => (state.success = payload),
    setTvArchiveList: (state, payload) => (state.tvArchiveList = payload),
    setCategories: (state, payload) => (state.categories = payload),
    setVodList: (state, payload) => (state.vodList = payload),
    setEpisodeList: (state, episodes) => (state.episodeList = episodes),
    setVideoData: (state, payload) => (state.videoData[payload.key] = payload.value),
    resetVideoData: (state) => {
      state.videoData.video = null;
      state.videoData.videoName = "";
      state.videoData.videoCategory = "";
      state.videoData.videoThumbnail = null;
      state.videoData.videoPrice = "";
      state.videoData.videoDescription = "";
      state.videoData.isSeries = false;
    },
    setSeriesData: (state, payload) => (state.seriesData[payload.key] = payload.value),
    resetSeriesData: (state) => {
      state.seriesData.videoFile = null;
      state.seriesData.seriesTitle = "";
      state.seriesData.seriesCategory = "";
      state.seriesData.seriesThumbnail = null;
      state.seriesData.seriesDescription = "";
      state.seriesData.seriesPrice = "";
      state.seriesData.episodeTitle = "";
      state.seriesData.episodeNumber = "";
      state.seriesData.episodeDescription = "";
      state.seriesData.isSeries = true;
    },
  },
  actions: {
    async fetchTvArchiveList({ commit }, payload) {
      commit("setIsLoading", true);
      try {
        const params = new URLSearchParams(payload).toString();
        const response = await adminInstance.get(`/tv-archive-list?${params}`);
        if (response.status === 200) {
          commit("setTvArchiveList", response.data.videos);
        }
      } catch (error) {
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 3000);
      } finally {
        commit("setIsLoading", false);
      }
    },

    async deleteTvArchive({ commit }, payload) {
      commit("setIsLoading", true);
      try {
        const response = await adminInstance.delete(`/tv-archive/${payload}`);
        if (response.status === 200) {
          commit("setSuccess", response.data.message);
          setTimeout(() => {
            commit("setSuccess", null);
          }, 3000);
        }
      } catch (error) {
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 3000);
      } finally {
        commit("setIsLoading", false);
      }
    },

    async fetchCategories({ commit }) {
      try {
        const response = await adminInstance.get("/categories");
        if (response.status === 200) {
          commit("setCategories", response.data.categories);
        }
      } catch (error) {
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 3000);
      }
    },

    async fetchVideoList({ commit }, payload) {
      commit("setIsLoading", true);
      try {
        const filters = {};
        for (const key in payload) {
          if (payload[key]) {
            filters[key] = payload[key];
          }
        }

        const params = new URLSearchParams(filters).toString();
        const response = await adminInstance.get(`/videos?${params}`);
        if (response.status === 200) {
          commit("setVodList", response.data.videos);
        }
      } catch (error) {
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 3000);
      } finally {
        commit("setIsLoading", false);
      }
    },

    async fetchEpisodeList({ commit }, payload) {
      commit("setIsLoading", true);
      try {
        const filters = {};
        for (const key in payload) {
          if (payload[key]) {
            filters[key] = payload[key];
          }
        }

        const params = new URLSearchParams(filters).toString();
        const response = await adminInstance.get(`/episodes?${params}`);
        if (response.status === 200) {
          commit("setEpisodeList", response.data.episodes);
        }
      } catch (error) {
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 3000);
      } finally {
        commit("setIsLoading", false);
      }
    },

    async fetchSeriesList({ commit }, payload) {
      commit("setIsLoading", true);
      try {
        const params = new URLSearchParams(payload).toString();
        const response = await adminInstance.get(`/series-list?${params}`);
        if (response.status === 200) {
          commit("setVodList", response.data.videos);
        }
      } catch (error) {
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 3000);
      } finally {
        commit("setIsLoading", false);
      }
    },

    async uploadNonSeriesVideo({ state, commit }) {
      commit("setIsLoading", true);
      try {
        const formData = new FormData();
        formData.append("video", state.videoData.video);
        formData.append("videoName", state.videoData.videoName);
        formData.append("videoCategory", state.videoData.videoCategory);
        formData.append("image", state.videoData.videoThumbnail);
        formData.append("videoPrice", state.videoData.videoPrice);
        formData.append("videoDescription", state.videoData.videoDescription);
        formData.append("isSeries", state.videoData.isSeries);

        const response = await adminInstance.post("/non-series", formData);
        if (response.status === 200) {
          commit("setSuccess", response.data.message);
          commit("resetVideoData");
          setTimeout(() => {
            commit("setSuccess", null);
          }, 3000);
        }
      } catch (error) {
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 3000);
      } finally {
        commit("setIsLoading", false);
      }
    },

    async uploadSeriesVideo({ state, commit }) {
      commit("setIsLoading", true);
      try {
        const formData = new FormData();
        formData.append("video", state.seriesData.videoFile);
        formData.append("seriesTitle", state.seriesData.seriesTitle);
        formData.append("seriesCategory", state.seriesData.seriesCategory);
        formData.append("image", state.seriesData.seriesThumbnail);
        formData.append("seriesPrice", state.seriesData.seriesPrice);
        formData.append("seriesDescription", state.seriesData.seriesDescription);
        formData.append("episodeTitle", state.seriesData.episodeTitle);
        formData.append("episodeNumber", state.seriesData.episodeNumber);
        formData.append("episodeDescription", state.seriesData.episodeDescription);
        formData.append("isSeries", state.seriesData.isSeries);

        const response = await adminInstance.post("/series", formData);
        if (response.status === 200) {
          commit("setSuccess", response.data.message);
          commit("resetSeriesData");
          setTimeout(() => {
            commit("setSuccess", null);
          }, 3000);
        }
      } catch (error) {
        commit("setError", error.message);
        setTimeout(() => {
          commit("setError", null);
        }, 3000);
      } finally {
        commit("setIsLoading", false);
      }
    },

    // upload tv schedule
    async uploadTvSchedule({ dispatch, commit }, formData) {
      commit("setIsLoading", true);
      try {
        console.log("store formData", formData);
        const response = await adminInstance.post("/upload-schedule", formData);
        if (response.status === 200) {
          dispatch("fetchTvArchiveList");
          commit("setSuccess", response.data.message);
          commit("resetSeriesData");
          setTimeout(() => {
            commit("setSuccess", null);
          }, 3000);
        }
      } catch (error) {
        commit("setError", error.response.data.message);
        setTimeout(() => {
          commit("setError", null);
        }, 8000);
      } finally {
        commit("setIsLoading", false);
      }
    },
  },
};
